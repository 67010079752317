const Cons = console;
export const Debug = {
    log: (...params: any) => {
        if (process.env.NODE_ENV === 'production') {
            return 0;
        } else {
            Cons.log(...params);
        }
    },
    error: (error: any) => {
        if (Object.prototype.hasOwnProperty.call(error, 'response')) {
            Cons.error(error.response);
            if (Object.prototype.hasOwnProperty.call(error.response, 'data')) {
                return error.response.data;
            } else {
                return error.response;
            }
        } else {
            Cons.error(error);
            return error;
        }
    },
};
